import React from "react";

import ContactIcon from "../../assets/icon/contact.svg";

import User from "../../assets/icon/user.svg";
import Phone from "../../assets/icon/phone.svg";
import Mail from "../../assets/icon/mail.svg";
import Input from "./../form/Input";
import Button from "./../button/Button";

const Contact = () => {
  return (
    <section id="Kontakt" className="contact container">
      <h2>Kontakt</h2>
      <div className="top">
        <div className="left">
          <div className="items">
            {/* <p className="item">
              <span>Ime: </span>
              <p>Matija Vasić</p>
            </p>
            <p className="item">
              <span>Telefon: </span>
              <p>062-29-04-80</p>
            </p> */}
            <p className="item">
              <span>Email: </span>
              <p>info@advertum.rs</p>
            </p>
          </div>
          <h3>
            Zainteresovani ste za saradnju? <span>Kontaktirajte</span> nas!
          </h3>
        </div>
        <div className="right">
          <img src={ContactIcon} alt="" />
        </div>
      </div>
      <div className="bottom">
        <Input icon={User} placeholder="Vaše ime" type="text" />
        <Input icon={Phone} placeholder="Vaš broj telefona" type="phone" />
        <Input icon={Mail} placeholder="Vaša email" type="email" />
        <Button text={"Pošalji zahtev"} />
      </div>
    </section>
  );
};

export default Contact;
