import React from "react";

import Client1 from "../../assets/image/client1.png";
import Client2 from "../../assets/image/client2.png";
import Client3 from "../../assets/image/client3.png";

const Client = () => {
  return (
    <section className="client container">
      <h2>
        Naši <span>klijenti</span>
      </h2>

      <div id={"Klijenti"} className="box">
        <img src={Client1} alt="" />
        <div className="info">
          <h4>1. Kompanije</h4>
          <p className="desc">
            Pružamo usluge kompanijama iz različitih delatnosti uključujući
            telekomunikacije, bankarstvo, osiguranje, maloprodaju, veleprodaju,
            proizvodnju, robu široke potrošnje, transport, energetiku, HoReCa,
            medije, izdavaštvo, IT, građevinarstvo, šumarstvo, poljoprivredu,
            nekretnine i profesionalne usluge.
          </p>
        </div>
      </div>
      <div className="box">
        <div className="info">
          <h4>2. Mala i srednja preduzeća</h4>
          <p className="desc">
            Uslužujemo mala i srednja preduzeća iz različitih oblasti. Pomažemo
            im da se bolje pozicioniraju i smađu na tržištu.
          </p>
        </div>
        <img src={Client2} alt="" />
      </div>
      <div className="box">
        <img src={Client3} alt="" />
        <div className="info">
          <h4>3. Vladine i nevladine organizacije</h4>
          <p className="desc">
            Nudimo usluge vladinim instituacijama (ministarstvima i agencijama),
            regionalnim i lokalnim samoupravama, univerzitetima, bolnicama,
            kompanijama u državnom vlasništvu, javnim preduzećima, donorskim i
            drugim međunarodnim organizacijama , kao i nevladinim
            organizacijama.
          </p>
        </div>
      </div>
    </section>
  );
};

export default Client;
