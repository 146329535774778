import React from "react";

import Logo from "../../assets/logo/logo.svg";
import Button from "./../button/Button";

const list = ["Usluge", "Klijenti", "Portfolio", "Kontakt"];

const Navigation = () => {
  return (
    <nav className="container">
      <div className="logo">
        <img src={Logo} alt="" />
      </div>

      <ul>
        {list.map((item, index) => (
          <li key={index}>
            <a href={`#${item}`}>{item}</a>
          </li>
        ))}
      </ul>

      <a href="mailto:info@advertum.rs">
        <Button text="Postavite pitanje" />
      </a>
    </nav>
  );
};

export default Navigation;
