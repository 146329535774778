import React from "react";

const Input = ({ icon, type, placeholder, onChange }) => {
  return (
    <div className="input">
      <img src={icon} alt="" />
      <input
        type={type}
        placeholder={placeholder}
        onChange={(e) => onChange(e.target.value)}
      />
    </div>
  );
};

export default Input;
