import React from "react";

import Strategy from "../../assets/icon/strategy.svg";
import Marketing from "../../assets/icon/marketing.svg";
import Financy from "../../assets/icon/financy.svg";
import Research from "../../assets/icon/research.svg";
import Organization from "../../assets/icon/organization.svg";

const content = [
  {
    title: "Strategija i unapređenje poslovanja",
    body: "Našim klijentima pomažemo prilikom značajnih promena u poslovanju, organizacijama i društvu i unapređenju svih segmenata njihovog poslovanja. Radimo na strategijskom i poslovnom planiranju, novim poslovnim modelima, modelima pružanja usluga i strategijama za izlazak na tržište. Nudimo primere najbolje prakse i prenosimo iskustva najboljih u ovom poslu. Nudimo procenu i analizu tržišta, organizaciju poslovanja i unapređenja istog. Unapređujemo koorporativnu kulturu i odnose među zaposlenima.Upravljamo promenama i realizujemo iste.",
    icon: Strategy,
  },
  {
    title: "Marketing",
    body: "Naše usluge podrazumevaju digitalnu strategiju, analizu i redizajn razvojnog puta, UI/UX, web i mobilni development, analizu i segmentaciju klijenata, analize fluktuacije kupaca, druge IT i Big Data usluge koje su usmerene na promociju klijenta i njegovo bolje poslovanja.",
    icon: Marketing,
  },
  {
    title: "Finansiranje",
    body: "Pomažemo klijentima u identifikovanju mogućnosti za finansiranje, razvoju projekata i pratimo ih kroz proces implementacije projekta ili poslovanje. Pružamo savetodavne usluge prilikom finansiranja od strane fondova, privlačenju investitora, kao i dobijanju  pozajmica u privatnom sektoru.",
    icon: Financy,
  },
  {
    title: "Istraživanja",
    body: "Istražujemo tržište, potencijalne klijente, kao i potencijalne kupce. Radimo sve mere radi unapređenja vašeg poslovanja i postizanju maksimalnih rezultata vašeg biznisa. Naš tim sprovodi relevantna istraživanja na reprezentativnim uzircija i kreira najbolju moguću strategiju za nastavak poslovanja.",
    icon: Research,
  },
  {
    title: "Organizacija poslovanja",
    body: "Uspostavljamo i vodimo kancelarije za upravljanje projektima, nudimo podršku klijentima u bilo kojoj fazi implementacije, pomažemo u razvoju i proširenju poslovanja, obezbeđujemo privremene kapacitete, popunjavamo nedostatke, i formiramo timove za eksterno pružanje usluga.",
    icon: Organization,
  },
];

const Services = () => {
  return (
    <section id="Usluge" className="services container">
      <h2>
        Naše <span>usluge</span>
      </h2>

      <div className="content">
        {content.map((item, key) => (
          <div className="box" key={key}>
            <img src={item.icon} alt="" />
            <h3>{item.title}</h3>
            <p className="desc">{item.body}</p>
          </div>
        ))}
      </div>
    </section>
  );
};

export default Services;
