import Navigation from "./components/navigation/Navigation";

import "./css/general.css";
import Hero from "./components/hero/Hero";
import Services from "./components/services/Services";
import Form from "./components/form/Form";
import Client from "./components/client/Client";
import Project from "./components/project/Project";
import Contact from "./components/contact/Contact";
import Footer from "./components/footer/Footer";

function App() {
  return (
    <div className="App">
      <Navigation />
      <Hero />
      <Services />
      <Form />
      <Client />
      {/* <Project /> */}
      <Contact />
      <Footer />
    </div>
  );
}

export default App;
