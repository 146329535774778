import React from "react";
import Button from "./../button/Button";

import Scroll from "../../assets/icon/scroll.svg";
import Main from "../../assets/icon/main.svg";

const Hero = () => {
  return (
    <section className="hero">
      <div className="container">
        <div className="left">
          <h1>
            Mi unapređujemo vaš <span>biznis</span>!
          </h1>
          <p className="desc">
            Uslužujemo mala i srednja preduzeća iz različitih oblasti. Pomažemo
            im da se bolje pozicioniraju i smađu na tržištu.
          </p>
          <div className="buttons">
            <a href="#Kontakt">
              <Button text="Radite sa nama" />
            </a>
            <div className="scroll">
              <img src={Scroll} alt="" />
              <p>Scroll za više</p>
            </div>
          </div>
        </div>
        <div className="right">
          <img src={Main} alt="" />
        </div>
      </div>
    </section>
  );
};

export default Hero;
