import React from "react";

import Logo from "../../assets/logo/logo2.svg";

import Facebook from "../../assets/icon/facebook.svg";
import Twitter from "../../assets/icon/twitter.svg";
import Instagram from "../../assets/icon/instagram.svg";
import Youtube from "../../assets/icon/youtube.svg";

const icons = [
  {
    link: "",
    icon: Facebook,
  },
  {
    link: "",
    icon: Twitter,
  },
  {
    link: "",
    icon: Instagram,
  },
  {
    link: "",
    icon: Youtube,
  },
];

const Footer = () => {
  return (
    <footer>
      <div className="container">
        <img src={Logo} className="logo" alt="" />
        <p className="copyright">© 2022 «Advertum». Sva prava zadržana</p>

        <div className="icons">
          {icons.map((item) => (
            <div className="icon">
              <img src={item.icon} alt="" />
            </div>
          ))}
        </div>
      </div>
    </footer>
  );
};

export default Footer;
