import React, { useState } from "react";
import Button from "./../button/Button";

import Mail from "../../assets/icon/mail.svg";
import Input from "./Input";

import axios from "axios";

const Form = () => {
  const [name, setName] = useState("BOBAN");
  const [email, setEmail] = useState();
  const [message, setMessage] = useState("MIRKOVIC");
  const [mailSent, setMailSent] = useState(false);
  const [error, setError] = useState(null);

  const sendMessage = (e) => {
    e.preventDefault();

    console.log(`name: ${name}, email: ${email}, message: ${message}`);

    axios({
      method: "post",
      url: process.env.PUBLIC_URL + "/form.php",
      headers: { "content-type": "application/json" },
      data: {
        name: name,
        email: email,
        message: message,
      },
    })
      .then((result) => {
        setMailSent(result.data.sent);
      })
      .catch((error) => {
        setError(error.message);

        console.log("error:", error);
      });
  };

  return (
    <section className="form container">
      <div className="background"></div>
      <div className="left">
        <h4>
          Zainteresovani ste za saradnju? <br /> <span>Kontaktirajte</span> nas!
        </h4>
        <p className="desc">Ostavite svoj email i mi ćemo vas kontaktirati</p>
      </div>
      <div>
        <form className="right" action="#" onSubmit={sendMessage}>
          <Input
            icon={Mail}
            placeholder="Vaš email"
            type="email"
            onChange={setEmail}
          />
          <Button text="Pošalji zahtev" />
        </form>
      </div>
    </section>
  );
};

export default Form;
